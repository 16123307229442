import { useSelector } from "react-redux";
import ContentNav from "../../datanavcontent";
import DefaultLayout from "../../layout/defaultlayout";
import "./style.css";
import Table from "../../components/table";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { Loader } from "rsuite";

export default function Invoice() {
  const language = useSelector((state) => state.language);
  const userData = localStorage.getItem("logAuth")
    ? JSON.parse(localStorage.getItem("logAuth"))
    : null;

  const [fatooraAgent, setFatooraAgent] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [fatoorahStatus, setFatooraStatus] = useState("");
  const [loader, setLoader] = useState(false);

  // Define table headers
  const headers = [
    {
      label: language === "ar" ? "اسم المنتج" : "Product Name",
      dataIndex: "product_name",
    },
    {
      label: language === "ar" ? "اسم المستخدم" : "User Name",
      dataIndex: "user_name",
    },
    {
      label: language === "ar" ? "رقم الفاتورة" : "Invoice Number",
      dataIndex: "fatoorah_id",
    },
    {
      label: language === "ar" ? "تاريخ الانشاء" : "Created At",
      dataIndex: "created_at",
      type: "children",
      children: ({ row }) => <p>{row?.created_at}</p>,
    },
    {
      label: language === "ar" ? "نوع الدفع" : "Payment Method",
      dataIndex: "payment_method",
    },
    {
      label: language === "ar" ? "رقم الشخص" : "Phone Number",
      dataIndex: "user_phone",
    },
    {
      label: language === "ar" ? "الحالة" : "Status",
      dataIndex: "paid",
      type: "children",
      children: ({ row }) => (
        <span>
          {row?.paid ? row?.paid : language === "ar" ? "غير مدفوع" : "Un Paid"}
        </span>
      ),
    },
    {
      label: language === "ar" ? "المبلغ" : "Amount",
      dataIndex: "price",
      type: "children",
      children: ({ row }) => <span>{parseFloat(row?.price).toFixed(2)}</span>,
    },
    {
      label: language === "ar" ? "حالة النحويل" : "Transfer Status",
      dataIndex: "price",
      type: "children",
      children: ({ row }) => <span>{parseInt(row?.done) ? "تم التحويل" : "لم يتم التحويل بعد"}</span>,
    },
    {
      label: language === "ar" ? "مصاريف إدارية" : "Administrative Expenses",
      dataIndex: "administrative_expenses",
      type: "children",
      children: ({ row }) => (
        <span>
          {(
            parseFloat(row?.price || 0) *
            (parseFloat(row?.administrative_expenses || 0) / 100)
          ).toFixed(1)}
        </span>
      ),
    },
    {
      label: language === "ar" ? "المبلغ الصافي" : "Total Amount",
      dataIndex: "submission_price",
      type: "children",
      children: ({ row }) => (
        <span>
          {(
            (parseFloat(row?.submission_price) || 0) -
            (parseFloat(row?.discount_amount) || 0) -
            (parseFloat(row?.submission_price) || 0) *
            (parseFloat(row?.baseeta_discount) || 0)
          ).toFixed(2)}
        </span>
      ),
    },
  ];

  // Fetch invoices based on agent_id
  const handleGetLocalInvoices = async () => {
    setLoader(true);
    try {
      const response = await axios.post(
        `https://baseeta-form.com/basita/getAgentFatoorah.php?agent_id=${userData?.agent_id}`
      );
      setFatooraAgent(response.data);
      setFilteredData(response.data);
    } catch (error) {
      console.error(error);
      toast.error(language === "ar" ? "حدث خطأ ما" : "There's a problem");
    } finally {
      setLoader(false);
    }
  };

  const [totalPrice, setTotalPrice] = useState({});
  const [AllTehweelsPrice, setAllTehweelsPrice] = useState(0);
  const [fatoorahTahweelStatus, setFatoorahTahweelStatus] = useState("All");

  const applyFilters = () => {
    let filtered = [...fatooraAgent];

    // Filter by Invoice Status
    if (fatoorahStatus) {
      filtered = filtered.filter((item) => {
        if (!item?.paid) {
          item.paid = "unpaid";
        }
        return item?.paid.toLowerCase() == fatoorahStatus.toLowerCase();
      });
    }

    if (fatoorahTahweelStatus?.toLowerCase() != "all") {
      filtered = filtered.filter((item) => {
        return item?.done == fatoorahTahweelStatus;
      });
    }

    // Filter by Date Range
    if (startDate) {
      filtered = filtered.filter(
        (item) => new Date(item.created_at) >= new Date(startDate)
      );
    }
    if (endDate) {
      filtered = filtered.filter(
        (item) => new Date(item.created_at) <= new Date(endDate)
      );
    }

    // Calculate Total Price
    const totalPriceMostahaq = filtered.reduce((acc, cur) => {
      if (!cur?.transfered) {
        const submissionPrice = parseFloat(cur?.submission_price) || 0;
        const discountAmount = parseFloat(cur?.discount_amount) || 0;
        const baseetaDiscount =
          parseFloat(cur?.baseeta_discount) || 0;
        const discountedPrice =
          submissionPrice - submissionPrice * baseetaDiscount;
        console.log(
          submissionPrice,
          discountAmount,
          submissionPrice * baseetaDiscount
        );

        return acc + discountedPrice;
      }
      return acc;
    }, 0);

    setTotalPrice({ price: totalPriceMostahaq, status: fatoorahStatus });

    setAllTehweelsPrice(filtered[0]?.totalTehweelatPrice);

    setFilteredData(filtered);
  };

  // Handle filter changes
  useEffect(() => {
    applyFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fatoorahStatus, startDate, endDate, fatooraAgent, fatoorahTahweelStatus]);

  // Fetch data on component mount and when date filters are applied
  useEffect(() => {
    handleGetLocalInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="h-container con-h">
      <DefaultLayout>
        <div className="childs">
          <div className="header">
            <ContentNav
              head={language === "ar" ? "فواتيرك" : "Your Invoices"}
            />
          </div>

          <div className="filters">
            {/* Start Date Filter */}
            <div className="inputField">
              <label htmlFor="startDate">
                {language === "ar" ? "تاريخ البدء" : "Start Date"}
              </label>
              <input
                type="date"
                id="startDate"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>

            {/* End Date Filter */}
            <div className="inputField">
              <label htmlFor="endDate">
                {language === "ar" ? "تاريخ الانتهاء" : "End Date"}
              </label>
              <input
                type="date"
                id="endDate"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
            <div className="inputField">
              <label htmlFor="fatoorahStatus">
                {language === "ar" ? "حالة الفاتورة من بسيطة" : "Baseeta Invoice Status"}
              </label>
              <select
                name="fatoorahStatus"
                id="fatoorahStatus"
                value={fatoorahTahweelStatus}
                onChange={(e) => setFatoorahTahweelStatus(e.target.value)}
              >
                <option value="all">{language === "ar" ? "الكل" : "All"}</option>
                <option value={0}>
                  {language === "ar" ? "لم يتم التحويل" : "Does not transfer"}
                </option>
                <option value={1}>
                  {language === "ar" ? "تم التحويل" : "Transfered"}
                </option>
              </select>
            </div>
            {/* Invoice Status Filter */}
            <div className="inputField">
              <label htmlFor="fatoorahStatus">
                {language === "ar" ? "حالة الدفع" : "Invoice Status"}
              </label>
              <select
                name="fatoorahStatus"
                id="fatoorahStatus"
                value={fatoorahStatus}
                onChange={(e) => setFatooraStatus(e.target.value)}
              >
                <option value="">{language === "ar" ? "الكل" : "All"}</option>
                <option value="paid">
                  {language === "ar" ? "مدفوع" : "Paid"}
                </option>
                <option value="unpaid">
                  {language === "ar" ? "غير مدفوع" : "UnPaid"}
                </option><option value="refunded">
                  {language === "ar" ? "تم استردادها" : "refunded"}
                </option>
                
              </select>
            </div>
            <span>
              {language === "ar"
                ? "إجمالي الفواتير"
                : "Invoices Total Price"}
              : {console.log(totalPrice?.price, AllTehweelsPrice)}
              {(
                (totalPrice?.price || 0) -
                (fatoorahStatus == "paid" || !fatoorahStatus || startDate || endDate
                  ? AllTehweelsPrice
                  : 0)
              ).toFixed(2)}
            </span>
          </div>

          {/* Loader */}
          {loader ? (
            <div className="loaderContainer">
              <Loader size="lg" />
            </div>
          ) : (
            <Table headers={headers} body={filteredData} />
          )}
        </div>
      </DefaultLayout>
    </div>
  );
}
